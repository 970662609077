<template>
  <div>
    <p>test</p>
    <v-container
      id="user-profile"
      fluid
      tag="section"
    >
      <v-row justify="center">
        <v-col
          cols="12"
          md="8"
        >
          <base-material-card>
            <template v-slot:heading>
              <div class="display-2 font-weight-light">
                Mon compte
              </div>

              <div class="subtitle-1 font-weight-light">
                Toutes mes informations
              </div>
            </template>

            <v-form>
              <v-container class="py-0">
                <v-row>
                  <v-col
                    cols="12"
                    md="4"
                  > 
                    <v-text-field
                      label="CERVO SARL"
                      disabled
                    />
                  </v-col>

                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      class="purple-input"
                      label="User Name"
                    />
                  </v-col>

                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      label="Email Address"
                      class="purple-input"
                    />
                  </v-col>

                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-text-field
                      label="First Name"
                      class="purple-input"
                    />
                  </v-col>

                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-text-field
                      label="Last Name"
                      class="purple-input"
                    />
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      label="Adress"
                      class="purple-input"
                    />
                  </v-col>

                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      label="City"
                      class="purple-input"
                    />
                  </v-col>

                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      label="Country"
                      class="purple-input"
                    />
                  </v-col>

                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      class="purple-input"
                      label="Postal Code"
                      type="number"
                    />
                  </v-col>

                  <v-col cols="12">
                    <v-textarea
                      class="purple-input"
                      label="About Me"
                      value="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
                    />
                  </v-col>

                  <v-col
                    cols="12"
                    class="text-right"
                  >
                    <v-btn
                      color="success"
                      class="mr-0"
                    >
                      Update Profile
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </base-material-card>
        </v-col>

        <v-col
          cols="12"
          md="4"
        >
          <base-material-card
            class="v-card-profile"
          >
            <!-- avatar="https://demos.creative-tim.com/vue-material-dashboard/img/marc.aba54d65.jpg" -->
            <v-card-text class="text-center">
              <h6 class="display-1 mb-1 grey--text">
                CEO / CO-FOUNDER
              </h6>

              <h4 class="display-2 font-weight-light mb-3 black--text">
                Alec Thompson
              </h4>

              <p class="font-weight-light grey--text">
                Don't be scared of the truth because we need to restart the human foundation in truth And I love you like Kanye loves Kanye I love Rick Owens’ bed design but the back is...
              </p>

              <v-btn
                color="success"
                rounded
                class="mr-0"
              >
                Follow
              </v-btn>
            </v-card-text>
          </base-material-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

    import NProgress from 'nprogress';
    import 'nprogress/nprogress.css';
    import axios from 'axios';

    export default {
      //

      data() {
        return {
            user:{ 
              password : "",
            },

        };

      },
      methods: {
      
        async loadFilter()
        {
            NProgress.start();

            console.log('in');

            try {
              
                const response = await axios.get('auth/user');
                this.user = response.data.data;
                this.user.hold_password = this.user.password;

                console.log(this.user);

            } catch (error) {
                this.$notify({
                type: 'error',
                title: "Echec de recuperation"
            });
            }

            NProgress.done();

        },

      },

      created () {
        this.loadFilter();
      }

    }
</script>
